var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "certification" },
    [
      _c("headbox", {
        attrs: {
          title: "实名认证",
          bgColor: "#39394d",
          isbackperson: true,
          isborder: "false",
          path: _vm.path
        }
      }),
      _c("div", { staticClass: "cerbox" }, [
        _c("div", { staticClass: "tip" }, [
          _vm._v(" 为保证您的账户安全，请先完成实名认证 ")
        ]),
        _c(
          "div",
          { staticClass: "rel tc imgboxlist" },
          [
            _c("h1", [_vm._v("请拍摄并上传身份证")]),
            _c("van-uploader", { attrs: { "after-read": _vm.afterRead } }, [
              _c("img", {
                staticClass: "idCard",
                attrs: { src: _vm.firstupsuccess, alt: "" }
              }),
              !_vm.nextBox
                ? _c("img", {
                    staticClass: "photobox",
                    attrs: {
                      src: require("../../assets/images/btn_camera_nor@2x.png"),
                      alt: ""
                    }
                  })
                : _vm._e(),
              _vm.nextBox
                ? _c("img", {
                    staticClass: "photobox",
                    attrs: { src: _vm.successIcon, alt: "" }
                  })
                : _vm._e()
            ]),
            _c(
              "van-uploader",
              { attrs: { "after-read": _vm.afterReadsecond } },
              [
                _c("img", {
                  staticClass: "idCard",
                  attrs: { src: _vm.upsuccess, alt: "" }
                }),
                !_vm.nextBox
                  ? _c("img", {
                      staticClass: "photobox",
                      attrs: {
                        src: require("../../assets/images/btn_camera_nor@2x.png"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.nextBox
                  ? _c("img", {
                      staticClass: "photobox",
                      attrs: { src: _vm.successIcon, alt: "" }
                    })
                  : _vm._e()
              ]
            ),
            _vm._m(0)
          ],
          1
        ),
        _c("div", { staticClass: "usercenter" }, [
          _c("h1", { staticClass: "tc" }, [_vm._v("请核对身份证信息内容")]),
          _c("div", { staticClass: "userbox" }, [
            _c("b", [_vm._v("姓 名")]),
            _c("i", [_vm._v(_vm._s(_vm.username))])
          ]),
          _c("div", { staticClass: "userbox" }, [
            _c("b", [_vm._v("身份证号")]),
            _c("i", [_vm._v(_vm._s(_vm.Idcard))])
          ]),
          _c("div", { staticClass: "userbox" }, [
            _c("b", [_vm._v("有效时间")]),
            _c("i", [_vm._v(_vm._s(_vm.idCardTime + _vm.idCardendTime))])
          ])
        ]),
        _c(
          "div",
          { staticClass: "tc" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn_yellow",
                style: { opacity: _vm.nextBox ? "1" : "0.7" },
                attrs: { disabled: !_vm.nextBox },
                on: { click: _vm.nextPage }
              },
              [_vm._v("下一步")]
            )
          ],
          1
        )
      ]),
      _c("Loadings", {
        attrs: { loadText: "身份证识别中", loadShow: _vm.isloading }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "proposal" }, [
      _c("p", [_vm._v("·建议拍摄时选择光线良好的环境 ")]),
      _c("p", [_vm._v("·请务必保证边框完整、字体清晰")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }